@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');

html {
    height: 100%;    
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    margin: 0;
    height: 100%;
    font-size: 14px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a {
    font-weight: 600;
    text-decoration: none;
    color: #0e7569;
}

#root {
    height: 100%;
}

.MuiTypography-root {
    font-family: Inter !important;
}

.MuiTypography-h1 {
    font-size: 50px !important;
}
